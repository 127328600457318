import HeroImageSVG from "../assets/MainImage.png";
import HowWorksImage from "../assets/HowWorks.png";
import OrcafinaLogoSVG from "../assets/OrcafinaLogo2.svg";
import LoopsLogoSVG from "../assets/Loops2Logo.svg";
import YCLogoSVG from "../assets/YCLogo.svg";
import DVLogoSVG from "../assets/DVLogo.svg";

import IconBoltSVG from "../assets/IconBolt.svg";
import IconCheckSVG from "../assets/IconCheck.svg";
import IconBoxSVG from "../assets/IconBox.svg";
import IconInfinitySVG from "../assets/IconInfinity.svg";

import "../App.css";

function LandingScreen() {
  return (
    <div
      style={{
        backgroundColor: "#FEF7F1",
        width: "100vw",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            padding: "0px 5%",
            height: "10vh",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            {/* <img
            src={OrcafinaLogoSVG}
            style={{ height: "1.5em", width: "auto", marginRight: "0.5em" }}
            alt="OrcafinaLogoSVG"
          /> */}
            <p
              style={{
                marginBlock: "0px",
                fontWeight: "700",
                fontSize: "20px",
              }}
            >
              Artisage
            </p>
          </div>
          {/* <div style={{ display: "flex", flexDirection: "row" }}>
            <div className="TopLinkText">
              <p
                style={{
                  marginBlock: "0px",
                  fontWeight: "400",
                  fontSize: "16px",
                }}
              >
                Features
              </p>
            </div>
            <div className="TopLinkText">
              <p
                style={{
                  marginBlock: "0px",
                  fontWeight: "400",
                  fontSize: "16px",
                }}
              >
                Contact
              </p>
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div className="TopLinkText">
              <p
                style={{
                  marginBlock: "0px",
                  fontWeight: "400",
                  fontSize: "16px",
                }}
              >
                Get access
              </p>
            </div>
          </div> */}
        </div>
        <div style={{ height: "90vh", display: "flex", alignItems: "center" }}>
          <div
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              padding: "0 3vw 0 5vw",
              // backgroundColor: "blue",
            }}
          >
            <img
              src={HeroImageSVG}
              className="HeroSectionImage floating"
              // style={{ height: "auto", width: "40vw" }}
              alt="HeroImageSVG"
            />
          </div>
          <div
            style={{
              flex: 1,
              // padding: "25\vh 0vw 0 0",
              // padding: "0 20vw 0 50px",
              // backgroundColor: "yellow",
            }}
            className="fadein"
          >
            <p
              style={{
                marginBlock: "0px",
                fontWeight: "700",
                fontSize: "60px",
              }}
            >
              Stunning <br /> product shots. <br />{" "}
              <span style={{ color: "#8B8600" }}>Lightning</span> fast.
            </p>
            <p
              style={{
                margin: "3vh 0 0 0",
                fontWeight: "400",
                fontSize: "18px",
                maxWidth: "30vw",
              }}
            >
              Instantly generate unlimited product imagery for your brand using
              breakthrough AI.{" "}
            </p>
            <div
              style={{
                display: "flex",
                marginTop: "3vh",
                height: "2.5em",
                alignItems: "center",
              }}
            >
              <a
                className="ContactButton"
                href="https://airtable.com/shrt8iTG9vNoD49Xm"
                target="_blank"
              >
                <p
                  style={{
                    //   color: "white",
                    fontWeight: "400",
                    fontSize: "14px",
                  }}
                >
                  Get access
                </p>
              </a>
              <p
                style={{
                  margin: "0 20px",
                  color: "black",
                  fontWeight: "400",
                  fontSize: "14px",
                }}
              >
                5X your sales with <br /> just a few clicks.
              </p>
            </div>
            <div
              style={{
                display: "flex",
                marginTop: "5vh",
                height: "2.5em",
                //   alignItems: "center",
              }}
            >
              <img
                src={YCLogoSVG}
                style={{ height: "1.5em", width: "auto", marginRight: "1.5em" }}
                alt="YCLogoSVG"
              />
              <img
                src={DVLogoSVG}
                style={{ height: "1.5em", width: "auto", marginRight: "1.5em" }}
                alt="DVLogoSVG"
              />
              <img
                src={LoopsLogoSVG}
                style={{ height: "1.5em", width: "auto", marginRight: "0em" }}
                alt="LoopsLogoSVG"
              />
            </div>
          </div>
        </div>
      </>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: "8vh 5%",
          // height: "100vh",
          backgroundColor: "white",
        }}
        className="fadein"
      >
        <p
          style={{
            marginBlock: "0px",
            fontWeight: "700",
            fontSize: "30px",
          }}
        >
          How <span style={{ color: "#8B8600" }}>Artisage</span> works
        </p>
        <p
          style={{
            margin: "3vh 0 5vh 0",
            fontWeight: "400",
            fontSize: "18px",
          }}
        >
          Upload images. Tell us a few styles. Get unlimited results.
        </p>
        <img src={HowWorksImage} className="HowWorksImage" alt="How" />
        <div className="orb1 floating" />
        <div className="orb2 floating" />
        <div className="orb3 floating" />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: "0px 5%",
          // height: "100vh",
          // backgroundColor: "white",
        }}
      >
        <p
          style={{
            margin: "1em",
            color: "black",
            fontWeight: "700",
            fontSize: "40px",
          }}
        >
          Increase your sales by 5X.
        </p>
        <div style={{ display: "flex" }}>
          <div className="featureBox">
            <div style={{ padding: "2em" }}>
              <img
                src={IconBoltSVG}
                style={{ height: "5em", width: "auto" }}
                alt="IconBoltSVG"
              />
              <p
                style={{
                  margin: "0",
                  fontWeight: "700",
                  fontSize: "26px",
                  color: "#8B8600",
                }}
              >
                Lightning Fast
              </p>
              <p
                style={{
                  marginBlock: "1em",
                  fontWeight: "400",
                  fontSize: "16px",
                  color: "#8B8600",
                }}
              >
                Immediately get generated product shots after upload.
              </p>
            </div>
          </div>
          <div className="featureBox">
            <div style={{ padding: "2em" }}>
              <img
                src={IconBoxSVG}
                style={{ height: "5em", width: "auto" }}
                alt="IconBoxSVG"
              />
              <p
                style={{
                  margin: "0",
                  fontWeight: "700",
                  fontSize: "26px",
                  color: "#8B8600",
                }}
              >
                Works out of the Box
              </p>
              <p
                style={{
                  marginBlock: "1em",
                  fontWeight: "400",
                  fontSize: "16px",
                  color: "#8B8600",
                }}
              >
                Works out of the box with existing product images.
              </p>
            </div>
          </div>
        </div>
        <div style={{ display: "flex" }}>
          <div className="featureBox">
            <div style={{ padding: "2em" }}>
              <img
                src={IconInfinitySVG}
                style={{ height: "5em", width: "auto" }}
                alt="IconInfinitySVG"
              />
              <p
                style={{
                  margin: "0",
                  fontWeight: "700",
                  fontSize: "26px",
                  color: "#8B8600",
                }}
              >
                Unlimited Power
              </p>
              <p
                style={{
                  marginBlock: "1em",
                  fontWeight: "400",
                  fontSize: "16px",
                  color: "#8B8600",
                }}
              >
                Our AI models can create scenes only limited by the boundaries
                of your creativity.
              </p>
            </div>
          </div>
          <div className="featureBox">
            <div style={{ padding: "2em" }}>
              <img
                src={IconCheckSVG}
                style={{ height: "5em", width: "auto" }}
                alt="IconCheckSVG"
              />
              <p
                style={{
                  margin: "0",
                  fontWeight: "700",
                  fontSize: "26px",
                  color: "#8B8600",
                }}
              >
                Seamless Use
              </p>
              <p
                style={{
                  marginBlock: "1em",
                  fontWeight: "400",
                  fontSize: "16px",
                  color: "#8B8600",
                }}
              >
                Our editor is intuitive and fast. No downloads needed.{" "}
              </p>
            </div>
          </div>
        </div>
        <a
          className="ContactButton"
          href="https://airtable.com/shrt8iTG9vNoD49Xm"
          target="_blank"
          style={{ margin: "2em" }}
        >
          <p
            style={{
              //   color: "white",
              fontWeight: "400",
              fontSize: "14px",
            }}
          >
            Get access →
          </p>
        </a>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          // justifyContent: "space-around",
          // alignItems: "space-around",
          padding: "0px 5%",
          // height: "15vh",
          backgroundColor: "black",
        }}
      >
        <div>
          <p
            style={{
              color: "white",
              // marginBlock: "0px",
              fontWeight: "400",
              fontSize: "16px",
              textAlign: "center",
            }}
          >

            © 2022 Artisage Inc. All Rights Reserved. <br />  <br /> 
            169 Madison Ave
            <br /> New York, NY 10016 <br/>
          </p>
        </div>
        {/* <div>
          <p
            style={{
              color: "black",
              // marginBlock: "0px",
              fontWeight: "400",
              fontSize: "16px",
              // textAlign: "center",
            }}
          >
            169 Madison Ave
            <br /> New York, NY 10016
          </p>
        </div> */}
      </div>
    </div>
  );
}

export default LandingScreen;
