import { BrowserRouter, Route, Routes } from "react-router-dom";
import useViewport from "./hooks/viewport";
import "./App.css";
import LandingScreen from "./screens/LandingScreen";
import LandingScreenMobile from "./screens/LandingScreenMobile";

function App() {
  const displayMobile = useViewport();

  return (
    <div className="AppOpenSans">
      <BrowserRouter>
        <Routes>
          <Route path="/">
            <Route
              path=""
              element={
                displayMobile ? <LandingScreenMobile /> : <LandingScreen />
              }
            />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
